<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-school" aria-hidden="true" style="font-size: 21px; margin-right: 3px; color: var(--iq-primary);"></i>
              Moderate Schools
            </h4>
          </template>
        </iq-card>
      </b-col>
      <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="tab-content">
            <div class="iq-card rounded p-3 bg-white">
              <h4 class="mb-2 primary-color">To Be Approved</h4>
              <b-form-input v-model="vmOrgTempSearch" placeholder="Search" @input="startOrgTempSearch(vmOrgTempSearch)"></b-form-input>
            </div>

            <div v-for="(org,index) in unapprovedOrgsObj" :key="index" class="mt-5">
              <iq-card className="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:body>
                  <div class="iq-badges text-left">
                    <div class="badges-icon">
                      <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org.org_profile_img">
                        <img :src="org.org_profile_img" class="avatar-80 rounded" alt="Organisation">
                      </b-avatar>
                      <b-avatar v-else class="avatar-80" :text="getFirstLetterOfAString(org.org_name)" >
                      </b-avatar>
                    </div>
                    <div class="d-flex">
                    <h5 class="mb-2 title twoLineOnly pointer" @click="goToDetails(org.org_id)">
                      {{org.org_name}}
                    </h5>
                    <i class="fa-solid fa-md fa-copy pointer ml-2 primary-color pull-right mt-1" @click="doCopy(org.org_name)" title="Copy id"></i>
                    </div>
                  </div>
                  <span class="mr-2 font-weight-normal" variant="primary" style="display: block;">
                    <small><i>School id: {{org.org_id}}</i></small>
                  </span>
                  <span class="mr-2 font-weight-normal" variant="primary">
                  {{org.org_city}}{{ " " }}{{org.org_country }}
                  </span>
                  <div class="float-right">
                  <b-button style="all:unset; font-weight: 500; cursor: pointer;" @click="approve_unapprove_School(org.org_id, 0)">
                    <span class="text-primary">Approve</span>
                  </b-button>
                  <button class="ml-2" style="all:unset; font-weight: 500; cursor: pointer;">
                    <span class="text-primary" @click="() => { orgId = org.org_id; showReplaceOrgModel = true }">Replace</span>
                  </button>
                  </div>
                </template>
              </iq-card>
            </div>
        </div>
      </b-col>

      <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div class="tab-content">
            <div class="iq-card rounded p-3">
            <h4 class="mb-2 primary-color">Approved & Listed Schools</h4>
              <b-form-input v-model="vmOrgSearch" placeholder="Search" @input="startOrgSearch(vmOrgSearch)"></b-form-input>
            </div>
            <div v-for="(org,index) in organisationListObj" :key="index" class="mt-5">
              <iq-card class="iq-card-stretch iq-card-height">
                <template v-slot:body>
                  <div class="iq-badges text-left" @click="goToDetails(org.org_id)" style="cursor: pointer;">
                    <div class="badges-icon">
                      <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="org.org_profile_img">
                        <img :src="org.org_profile_img" class="avatar-80 rounded" alt="Organisation">
                      </b-avatar>
                      <b-avatar v-else class="avatar-80" :text="getFirstLetterOfAString(org.org_name)" >
                      </b-avatar>
                    </div>
                    <h5 class="mb-2 title twoLineOnly">
                      {{org.org_name}}
                    </h5>
                  </div>
                  <span class="mr-2 font-weight-normal" variant="primary" style="display: block;">
                    <small><i>School id: {{org.org_id}}</i></small>
                    <i class="fa-solid fa-md fa-copy pointer ml-2 primary-color" @click="doCopy(org.org_id)" title="Copy id"></i>
                  </span>
                  <span class="mr-2 font-weight-normal" variant="primary">
                  {{org.org_city}}{{ " " }}{{org.org_country }}
                  </span>
                  <div class="float-right">
                    <b-button style="all:unset; font-weight: 500; cursor: pointer;" @click="approve_unapprove_School(org.org_id, 3)">
                      <span class="text-primary">Unapprove</span>
                    </b-button>
                  </div>
                </template>
              </iq-card>
            </div>
            <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="showLoadMoreBtn">
            <button class="btn btn-primary" @click="loadMore('allOrganisations')">
              Load More
            </button>
          </div>
        </div>
      </b-col>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>
    </b-row>
    <b-modal v-model="showReplaceOrgModel" size="md " title="Replace with existing school">
      <div class="col-md-12 mb-3">
        <label for="validationowner_id">School id:</label>
        <input
          v-model="vmSchId"
          type="text"
          class="form-control"
          required
        />
      <span class="primary-color">Copy & Paste the School Id from the Approved & Listed Schools</span>
      </div>
      <template #modal-footer>
        <b-button class="btn btn-sm m-1" variant="primary" @click="() => showReplaceOrgModel = false">Close</b-button>
        <b-button class="btn btn-sm m-1" variant="primary" @click="replaceOrg()">Replace</b-button>
    </template>
    </b-modal>
  </b-container>
</template>
<style scoped >
  .iq-card-stretch{
    border-radius: 20px;
  }
  .followButton{
    min-height: unset;
    max-height: 50px;
  }
  .iq-card{
    background: transparent !important;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit"
import { Organisations } from "../../../FackApi/api/organisation.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "Organisation Moderator",
  data () {
    return {
      apiName: "organisation_publish_unpublish",
      vmSchId: "",
      orgId: "",
      organisationListObj: {},
      unapprovedOrgsObj: {},
      allOrganisationCount: 0,
      showReplaceOrgModel: false,
      currentPage: 1,
      totalPage: "",
      showLoadMoreBtn: false,
      vmOrgSearch: "",
      vmOrgTempSearch: "",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "School Moderation"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    organisations () {
      return this.$store.getters["Organisation/selectedOrganisation"]
    },
    schools () {
      return this.$store.getters["Schools/selectedSchool"]
    },
    universities () {
      return this.$store.getters["Universities/selectedUniversity"]
    }
  },
  watch: {
    "$route.path": function (newPath, oldPath) {
      this.refreshPage = newPath
      this.organisationListObj = {}
      this.currentPage = 1
      this.loadRequiredData()
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.organisationsList()
      this.unapprovedOrgs()
    },
    /**
       * goToDetails
       */
    goToDetails (orgId) {
      window.open(`/organisations/SCH/${orgId}`, "_blank")
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * replaceOrg
     */
    async replaceOrg () {
      let orgTempObj = {
        old_org_id: this.orgId,
        new_org_id: this.vmSchId
      }

      const replaceOrgResp = await Organisations.organisation_reject_replace(this, orgTempObj)
      if (!replaceOrgResp.resp_status) {
        this.toastMsg = replaceOrgResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      // Updating the frontend
      delete this.unapprovedOrgsObj[this.orgId]
      this.unapprovedOrgsObj = { ...this.unapprovedOrgsObj }
      this.showReplaceOrgModel = false

      this.toastMsg = "School Replaced"
      this.toastVariant = "success"
      this.showToast = true
    },
    /**
     * approve_unapprove_School
     */
    async approve_unapprove_School (schoolId, deleted) {
      // Publishing the school, once it is approved/unapprove
      let orgObj = {
        org_id: schoolId,
        deleted: deleted
      }

      const orgEditResp = await Organisations.organisation_publish_unpublish(this, orgObj)
      if (orgEditResp.resp_status) {
        // Updating the frontend
        let newSchoolObj = {}

        if (orgObj.deleted == "0") {
          // Approved
          newSchoolObj[schoolId] = this.unapprovedOrgsObj[schoolId]

          this.organisationListObj = { ...newSchoolObj, ...this.organisationListObj }
          delete this.unapprovedOrgsObj[schoolId]
          this.unapprovedOrgsObj = { ...this.unapprovedOrgsObj }

          this.toastMsg = "School Approved"
        }
        else {
          // UnApproved
          newSchoolObj[schoolId] = this.organisationListObj[schoolId]

          this.unapprovedOrgsObj = { ...newSchoolObj, ...this.unapprovedOrgsObj }
          delete this.organisationListObj[schoolId]
          this.organisationListObj = { ...this.organisationListObj }

          this.toastMsg = "School Unapproved"
        }

        this.toastVariant = "success"
        this.showToast = true
      }
      else if (orgEditResp.resp_code == "NOT_AUTHORIZED_API") {
        this.toastMsg = orgEditResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
      }
      else {
        this.toastMsg = "Error in approval"
        this.toastVariant = "danger"
        this.showToast = true
      }
    },
    /*
     * organisationsList
     */
    async organisationsList (searchString = null) {
      try {
        let filter = { type: ["SCH"] }
        filter = { ...filter }

        if (searchString && searchString.length >= 3) {
          filter = { ...filter,
            where: {
              "OR":
                [
                  {
                    org_name: searchString
                  },
                  {
                    org_desc: searchString
                  },
                  {
                    org_city: searchString
                  },
                  {
                    org_country: searchString
                  },
                  {
                    org_founding_year: searchString
                  },
                  {
                    org_avg_tuition_min: searchString
                  },
                  {
                    org_avg_tuition_max: searchString
                  },
                  {
                    org_scholarship_min: searchString
                  },
                  {
                    org_scholarship_max: searchString
                  },
                  {
                    org_application_deadline: searchString
                  }
                ]
            }
          }
        }

        let organisationsListResp = await Organisations.organisationList(this, filter, this.currentPage)
        if (organisationsListResp && organisationsListResp.resp_status) {
          if (organisationsListResp.resp_data.currentPage) {
            this.currentPage = parseInt(organisationsListResp.resp_data.currentPage)
          }

          if (organisationsListResp.resp_data.totalPage) {
            this.totalPage = parseInt(organisationsListResp.resp_data.totalPage)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          this.allOrganisationCount = organisationsListResp.resp_data.totalCount

          if (this.currentPage === 1) {
            // If the user is performing a search then include his search results only in the list
            this.organisationListObj = { ...organisationsListResp.resp_data.data }
          }
          else {
            this.organisationListObj = { ...this.organisationListObj, ...organisationsListResp.resp_data.data }
          }
        }
        else {
          this.organisationListObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationsList() and Exception:", err.message)
      }
    },
    /**
     * unapprovedOrgs
     */
    async unapprovedOrgs (searchString = null) {
      try {
        let filter = { type: ["SCH"] }
        filter = { ...filter, org_status: 3 }

        if (searchString && searchString.length >= 3) {
          filter = { ...filter,
            where: {
              "OR":
                [
                  {
                    org_name: searchString
                  },
                  {
                    org_desc: searchString
                  },
                  {
                    org_city: searchString
                  },
                  {
                    org_country: searchString
                  },
                  {
                    org_founding_year: searchString
                  },
                  {
                    org_avg_tuition_min: searchString
                  },
                  {
                    org_avg_tuition_max: searchString
                  },
                  {
                    org_scholarship_min: searchString
                  },
                  {
                    org_scholarship_max: searchString
                  },
                  {
                    org_application_deadline: searchString
                  }
                ]
            }
          }
        }

        let unapprovedOrgListResp = await Organisations.organisationList(this, filter, this.currentPage)
        if (unapprovedOrgListResp && unapprovedOrgListResp.resp_status) {
          if (unapprovedOrgListResp.resp_data.currentPage) {
            this.currentPage = parseInt(unapprovedOrgListResp.resp_data.currentPage)
          }

          if (unapprovedOrgListResp.resp_data.totalPage) {
            this.totalPage = parseInt(unapprovedOrgListResp.resp_data.totalPage)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          this.allOrganisationCount = unapprovedOrgListResp.resp_data.totalCount

          if (this.currentPage === 1) {
            // If the user is performing a search then include his search results only in the list
            this.unapprovedOrgsObj = { ...unapprovedOrgListResp.resp_data.data }
          }
          else {
            this.unapprovedOrgsObj = { ...this.unapprovedOrgsObj, ...unapprovedOrgListResp.resp_data.data }
          }
        }
        else {
          this.unapprovedOrgsObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at unapprovedOrgs() and Exception:", err.message)
      }
    },
    /**
     * startOrgSearch
     */
    startOrgSearch (vmOrgSearch) {
      if (vmOrgSearch && vmOrgSearch.length < 4) {
        // Donot fire api if search string length is less than 4
        return
      }

      this.currentPage = 1
      this.organisationsList(vmOrgSearch)
    },
    /**
     * startOrgTempSearch
     */
    startOrgTempSearch (vmOrgTempSearch) {
      if (vmOrgTempSearch && vmOrgTempSearch.length < 4) {
        // Donot fire api if search string length is less than 4
        return
      }

      this.currentPage = 1
      this.unapprovedOrgs(vmOrgTempSearch)
    },
    /**
     * loadMore()
     */
    loadMore (currentTab) {
      try {
        let currPage = this.currentPage
        this.currentPage = parseInt(currPage) + 1
        if (this.vmOrgSearch && currentTab === "allOrganisations") {
          this.organisationsList(this.vmOrgSearch, true)
        }
        else if (currentTab === "allOrganisations") {
          this.organisationsList()
          // Passing searchString = null, loadViaLoadMore = true so as not to use the storeData and make an api call
        }
      }
      catch (err) {
        console.error("Exception occured at loadMore() and Exception", err.message)
      }
    }
  }
}
</script>
